<template>
    <div style="background-color: #293345" class="w-8/12 md:w-9/12 lg:w-8/12 pt-3 pb-5 flex flex-col relative items-center mx-auto h-full rounded-2xl">
        
        <span class="text-xs text-secondary z-10">{{ area.objective_amount | reduceBigNumbers(1)}}</span>
        <div style="top: 2rem" class="z-20 h-3 -mt-2px w-2px bg-main absolute"></div>

        <div class="w-14rem md:w-16rem lg:w-72 h-14rem md:h-16rem lg:h-72 relative flex flex-col z-50">
            <div class="w-full h-full">
  
                <Gauge 
                    v-if="area.percentage < 100"
                    chartId="oppor" 
                    :nameData="'Alumnos'" 
                    :secondName="'Oportunidades'" 
                    :gaugeData="area.percentage"
                    color="#0089AA" 
                    secondColor="#8958ff" 
                    backColor="#121822"
                />
                <Gauge 
                    v-else 
                    chartId="oppor" 
                    :nameData="'Alumnos'" 
                    :secondName="'Oportunidades'" 
                    :gaugeData="area.percentage - 100"
                    color="#1ff2ab" 
                    secondColor="#8958ff" 
                    backColor="#0089AA"
                />
            </div>

            <div class="w-full h-full absolute inset-0 flex justify-center flex-col">
                <span class="text-mainSecondary font-semibold text-3xl">{{ area.amount | reduceBigNumbers(1)}}</span>
                <span class="text-secondary capitalize text-s">euros</span>
                <span class="text-main text-sm opacity-50 font-medium w-1/3 mx-auto border-t border-body mt-1 pt-1">{{ area.percentage | numberFormat(0) }}%</span>
            </div>
        </div>
        <span class="text-text font-medium text-lg block mt-auto">{{ area.name }}</span>
        <img class="w-full h-auto absolute bottom-0 left-0 right-0 z-0 opacity-75" style="mix-blend-mode: overlay;" :src="'img/fish-7.png'" />
    </div>
</template>

<script>
    // import Pie from '@/pages/inside/sections/dashboards/charts/Pie';
    import Gauge from '@/pages/inside/sections/dashboards/charts/Gauge';
    export default {
        name: 'AreaPie',
        components: {
            // Pie,
            Gauge
        },
        props: {
            area: {
                type: Object,
                required: true
            }
        }
    }
</script>