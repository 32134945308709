<template>
    <div>
        <Rotate />
        <transition name="fade" mode="out-in" class="z-50">

            <div class="w-screen md:w-3/5 max-w-mobile lg:max-w-tablet h-full bg-body flex flex-col min-h-screen mx-auto pb-2 no-mobile-shadow">

                <Header class="z-20" @openSearch="openSearch" :closeSearch="closeSearch"/>

                <transition name="fade" mode="out-in" class="z-50">
                    <div v-if="searchField" class="w-full flex justify-center items-center mx-2 z-10 relative">
                        <input type="text" id="searchText" v-model="search"
                               class="search-input focus:outline-none bg-module h-10 w-8/12 px-4 text-white"
                               @keyup.enter="goSearch" placeholder="Buscar curso..."/>
                        <div @click="goSearch" class="bg-main text-white w-10 h-10  flex-center"><i
                                class="mdi mdi-magnify text-xl"></i></div>
                        <div class="bg-box text-purple w-10 h-10 rounded-lg flex-center search-clean"
                             @click="cleanSearch"><i class="mdi mdi-close-circle"></i></div>
                    </div>
                </transition>


                <Headline class="relative z-10" :full="true">
                    <template slot="headlineTitle">
                        Resumen ejecutivo
                    </template>
                    <template slot="headlineSubtitle">
                        {{ headlineSubtitle }}
                    </template>
                </Headline>

                <div style="background-color: rgba(18, 24, 34, .5)" class="w-full h-48 md:h-64 lg:h-24rem xl:h-48 relative">
                    <img class="absolute w-full bottom-0 left-0 right-0 z-0" src="img/waves.svg">

                    <Request model="Summary" :immediate="true" action="students" @success="setStudentsData"
                             @error="onSetStudentsDataError" :form="studentsFilters"/>
                    <Request model="Summary" :immediate="true" action="visits" @success="setVisitsData"
                             @error="onSetVisitsDataError" :form="visitsFilters"/>

                    <Carousell
                        v-if="students_data && visits_data"
                        :maxLength="2"
                        v-slot="{ active }"
                        @headLineText="headLineText"
                        ref="carousellSummary"
                        class="h-48 md:h-64 lg:h-24rem xl:h-48">
                        <CarousellItem class="flex-none relative">
                            <i v-if="active === 0" @click="nextItem" style="top: 50%"
                               class="absolute right-0 z-10 transform -translate-y-1/2 not-italic mdi mdi-chevron-right text-main text-4xl"></i>
                            <SummaryPie v-if="students_data.students_target_percent_reached < 100" class="relative"
                                :color="'#77AAD6'" :data="students_data.students"
                                :secondData="students_data.students_target"
                                :gaugeData="students_data.students_target_percent_reached">

                                <template #summaryPieText>
                                    {{ students_data.students | numberFormat }}
                                </template>
                                <template #summaryTitle>
                                    Captación
                                </template>
                                <template #summarySubtitle>
                                    Alumnos
                                </template>
                            </SummaryPie>
                            <SummaryPie v-else class="relative" :color="'#10f0dc'" :data="students_data.students"
                                :secondData="students_data.students_target"
                                :gaugeData="students_data.students_target_percent_reached">

                                <template #summaryPieText>
                                    {{ students_data.students | numberFormat }}
                                </template>
                                <template #summaryTitle>
                                    Captación
                                </template>
                                <template #summarySubtitle>
                                    Alumnos
                                </template>
                            </SummaryPie>
                        </CarousellItem>

                        <CarousellItem class="flex-none relative">
                            <i @click="prevItem" style="top: 50%"
                               class="absolute left-0 z-10 transform -translate-y-1/2 not-italic mdi mdi-chevron-left text-main text-4xl"></i>
                            <div class="w-full h-full flex flex-col justify-center">
                                <span class="text-text text-lg lg:text-2xl xl:text-lg font-medium">Visitas web a cursos</span>
                                <span class="text-main text-sm lg:text-lg xl:text-sm -mt-1">edem.eu</span>
                                <button @click="goToTraffic"
                                        class="flex items-center justify-around bg-main rounded-full py-1 lg:py-2 xl:py-1 px-3 mx-auto mt-4 w-48 lg:w-64 xl:w-48 focus:outline-none">
                                    <i class="not-italic text-clientsSecondary mdi mdi-google-analytics text-xl"></i>
                                    <span class="text-text">
                                    <span v-if="visits_data.visits_target_percent_reached > 0">+</span>
                                    {{ visits_data.visits_target_percent_reached | numberFormat }}%
                                </span>
                                    <span class="text-clientsSecondary text-sm">Ver visitas</span>
                                </button>
                            </div>
                        </CarousellItem>
                    </Carousell>


                </div>
                <Headline class="z-10">
                    <template slot="headlineTitle">
                        Áreas
                    </template>
                    <template slot="headlineSubtitle">
                        {{ areas.length }} áreas
                    </template>
                </Headline>

                <div class="flex-1 my-auto max-h-120 lg:max-h-140 xl:max-h-120 w-full pb-1">
                    <All model="Area" :immediate="true" ref="areasRef" @success="setAreas" :query="area_query">
                        <Carousell
                                class="h-24rem md:h-90 lg:h-94 xl:h-24rem w-full"
                                :maxLength="areas.length"
                                v-slot="{ active }"
                        >
                            <CarousellItem
                                    v-for="(area, index) in areas"
                                    :key="index"
                                    class="transition-all duration-500 ease-in-out"
                                    :class="{
                                'transform translate-x-32 lg:translate-x-48 xl:translate-x-32 scale-75': index < active,
                                'transform -translate-x-32 lg:-translate-x-48 xl:-translate-x-32 scale-75': index > active,
                            }"
                            >
                                <AreaPie v-if="!area.type||area.type&&area.type!=='area_amount'" @goToArea="goToArea(area)" :area="area"/>
                                <AreaAmountsPie v-if="area.type&&area.type==='area_amount'"  :area="area"/>
                            </CarousellItem>
                        </Carousell>
                    </All>
                </div>
            </div>
        </transition>

        <All model="Course" ref="searchCoursesRef" :query="{text:search, scope:'com'}" @success="setSearchCourses"/>

        <portal v-if="showSearchResults" to="modals">
            <transition name="fade" mode="out-in" appear>
                <div class="absolute inset-0 w-screen h-screen bg-body bg-opacity-75 bg-blur z-50"></div>
            </transition>
            <transition name="slide-fade" mode="out-in" appear>
                <Modal :isSearch="true" :selected="selected" :searchCourses="searchCourses" :search="search" @closeModalSearch="closeModalSearch">
                    <template slot="modalTitle">
                        <span class="text-3xl">Buscando</span>
                    </template>
                    <template slot="modalSubtitle">
                        "{{search}}"
                    </template>
                </Modal>
            </transition>
        </portal>

    </div>
</template>

<script>
    import Header from "@/pages/inside/sections/dashboards/Header";
    import Rotate from "@/pages/inside/sections/dashboards/Rotate";
    import Headline from "@/pages/inside/sections/dashboards/Headline";
    import {Carousell, CarousellItem} from '@/pages/inside/sections/dashboards/carousell';
    import SummaryPie from '@/pages/inside/sections/dashboards/main/SummaryPie';
    import AreaPie from '@/pages/inside/sections/dashboards/main/AreaPie';
    import AreaAmountsPie from '@/pages/inside/sections/dashboards/main/AreaAmountsPie';
    import All from "../../../../api/components/All";
    import Request from "../../../../api/components/Request";

    import {actions} from "@/store";
    import Modal from "./Modal";

    export default {
        name: 'DashboardMain',
        components: {
            Modal,
            Request,
            All,
            Header,
            Rotate,
            Headline,
            Carousell,
            CarousellItem,
            SummaryPie,
            AreaPie,
            AreaAmountsPie
        },

        data() {
            return {
              colors: ['#77AAD6', '#5A9DEC', '#0BCEFC'],
                summary: [
                    {id: 0, title: 'Captación', subtitle: 'Alumnos', data: '22.455'},
                ],
                selected: null,
                areas: [],
                students_data: {
                    students: null,
                    students_target: null,
                    students_target_percent_reached: null
                },
                visits_data: {
                    current_visits: null,
                    prev_visits: null,
                    visits_target_percent_reached: null
                },
                area_query: {
                    scope: 'com',
                    is_home:true,
                    from: this.startDayFilter().startOf('year').format('YYYY-MM-DD'),
                    to: this.startDayFilter().endOf('year').format('YYYY-MM-DD')
                },
                headlineSubtitle: this.januaryToDecemberTitle(),
                studentsFilters: {
                    from: this.startDayFilter().startOf('year').format('YYYY-MM-DD'),
                    to: this.startDayFilter().endOf('year').format('YYYY-MM-DD')
                },
                visitsFilters: {
                    from: this.$dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
                    to: this.$dayjs().endOf('day').format('YYYY-MM-DD')
                },
                search: '',
                searchField: false,
                closeSearch: false,
                showSearchResults: false,
                searchCourses: false,
                changedItem: null,
            }
        },
        computed: {
            swiper() {
                return this.$refs.summary.$swiper;
            },
        },
        watch: {
            search() {
                this.$refs.searchCoursesRef.request();
            },
            headlineSubtitle(n) {
                this.headlineSubtitle = n;
            },
        },
        methods: {
          januaryToDecemberTitle(){
            return this.startDayFilter().year();//'Enero ' + year + '-Diciembre ' + year
          },
          startDayFilter(){
            let today = this.$dayjs();
            let limitDate = today.year()+'-'+'12-01';
            if(today.diff(limitDate, 'days')>=0){
              return today.add(1, 'years');
            }
            return today;
          },
            headLineText(headlineSubtitle) {
                this.headlineSubtitle = headlineSubtitle;
            },
            openSearch() {
                this.searchField = !this.searchField;
                this.closeSearch = !this.closeSearch;
            },
            closeModalSearch() {
                this.showSearchResults = !this.showSearchResults;
            },
            goSearch() {
                actions.setSearch(this.search);
                this.showSearchResults = !this.showSearchResults;
            },
            cleanSearch() {
                this.search = '';
            },
            nextItem() {
                this.$refs['carousellSummary'].next();
                this.headlineSubtitle = 'Últimos 30 días';
            },
            prevItem() {
                this.$refs['carousellSummary'].prev();
                this.headlineSubtitle = this.januaryToDecemberTitle();
            },
            goToTraffic() {
                this.$router.push('/traffic');
            },
            goToArea(area) {
                this.selected = area;
                this.$router.push('/courses/' + area.id)
            },
            setStudentsData(students_data) {
                this.students_data = students_data;
            },
            onSetStudentsDataError() {

            },
            setVisitsData(visits_data) {
                this.visits_data = visits_data;
            },
            onSetVisitsDataError() {

            },
            setAreas(areas) {
                this.areas = areas.data;
            },
            setSearchCourses(searchCourses) {
                this.searchCourses = searchCourses.data;
            },
        }
    }
</script>

<style>
    .sizeSvg {
        transform: translateX(47%) scale(2);
        top: 20px;
    }

    .sizeSvg2 {
        transform: translateX(-50%) scale(2);
        top: 20px;
    }

</style>