<template>
    <div class="h-full w-full flex items-center ml-4 justify-around px-4">
        <div class="h-32 md:h-40 lg:h-56 xl:h-32 w-32 md:w-40 lg:w-56 xl:w-32 relative">
            <div v-if="data" class="h-full w-full z-50 relative">
                <Gauge :gaugeData="gaugeData" :color="color" chartId="captation" :nameData="'Captación'" />
            </div>
            <div class="h-32 md:h-40 lg:h-56 xl:h-32 w-32 md:w-40 lg:w-56 xl:w-32 flex flex-col justify-center inset-0 absolute z-0">
                <span class="font-medium text-lg text-subtitle">
                     <slot name="summaryPieText" />
                </span>
                <span class="font-medium text-xs text-subtitle opacity-50">{{gaugeData | numberFormat(0)}}%</span>
            </div>
        </div>
        <div class="flex-1 min-w-0 h-full flex flex-col justify-center -ml-4">
            <span class="text-text text-lg lg:text-2xl xl:text-lg font-medium">
                <slot name="summaryTitle" />
            </span>
            <span class="text-sm lg:text-lg xl:text-sm text-subtitle -mt-1">
                <slot name="summarySubtitle" />
            </span>
            <div class="flex items-center justify-center mt-2">
                <i class="mdi mdi-bullseye-arrow not-italic text-fontSecondary mr-3"></i>
                <span class="text-sm text-fontSecondary ml-3">{{ secondData | numberFormat }}</span>
            </div>
        </div>
    </div>
</template>

<script>

    import Gauge from '@/pages/inside/sections/dashboards/charts/Gauge';
    // import Pie from '@/pages/inside/sections/dashboards/charts/Pie';

    export default {
        name: 'SummaryPie',
        props: ['color','data','secondData', 'gaugeData'],
        components: {
            Gauge,
            // Pie
        }
    }
</script>