<template>
    <div @click="goToArea(area)" style="background-color: #293345" class="w-8/12 md:w-9/12 lg:w-8/12 pt-3 pb-5 flex flex-col relative items-center mx-auto h-full rounded-2xl">
        
        <span class="text-xs text-secondary z-10">{{ area.projected_customers_target }}</span>
        <div style="top: 2rem" class="z-20 h-3 -mt-2px w-2px bg-main absolute"></div>
        
        <div class="w-14rem md:w-16rem lg:w-72 h-14rem md:h-16rem lg:h-72 relative flex flex-col z-50">
            <div class="w-full h-full">
  
                <Gauge 
                    v-if="area.customers_target_percent_reached < 100" 
                    chartId="oppor" 
                    :nameData="'Alumnos'" 
                    :secondName="'Oportunidades'" 
                    :gaugeData="area.customers_target_percent_reached" 
                    :secondData="areaSecondData" 
                    color="#0089AA" 
                    secondColor="#8958ff" 
                    backColor="#121822"
                />
                <Gauge 
                    v-else 
                    chartId="oppor" 
                    :nameData="'Alumnos'" 
                    :secondName="'Oportunidades'" 
                    :gaugeData="area.customers_target_percent_reached - 100" 
                    :secondData="areaSecondData" 
                    color="#1ff2ab" 
                    secondColor="#8958ff" 
                    backColor="#0089AA"
                />
            </div>

            <div class="w-full h-full absolute inset-0 flex justify-center flex-col">
                <span v-if="area.has_opportunities_plus" class="text-opportunitiesPlus text-lg font-semibold">{{ area.opportunities_plus_size }}<span class="text-opportunitiesPlus opacity-50 text-xs font-medium ml-1">{{ areaSecondData | numberFormat(0) }}%</span></span>
                <span v-if="area.has_opportunities_plus" class="text-secondary capitalize" :class="{'text-xs':area.has_opportunities_plus, 'text-sm' : !area.has_opportunities_plus }">Admitidos pagados</span>
                <div v-if="area.has_opportunities_plus" class="bg-body w-1/2 mx-auto h-px my-2"></div>
                <span class="text-mainSecondary font-semibold" :class="{'text-lg' : area.has_opportunities_plus, 'text-3xl': !area.has_opportunities_plus }">{{ area.customers_size }}<span v-if="area.has_opportunities_plus" class="text-main opacity-50 text-xs font-medium ml-1">{{ area.customers_target_percent_reached | numberFormat(0) }}%</span></span>
                <span class="text-secondary capitalize" :class="{'text-xs':area.has_opportunities_plus, 'text-sm' : !area.has_opportunities_plus }">alumnos</span>

                <span v-if="!area.has_opportunities_plus" class="text-main text-sm opacity-50 font-medium w-1/3 mx-auto border-t border-body mt-1 pt-1">{{ area.customers_target_percent_reached | numberFormat(0) }}%</span>
            </div>
        </div>
        <span class="text-text font-medium text-lg block mt-auto">{{ area.name }}</span>
        <img class="w-full h-auto absolute bottom-0 left-0 right-0 z-0 opacity-75" style="mix-blend-mode: overlay;" :src="'img/fish-'+area.id+'.png'" />
    </div>
</template>

<script>
    // import Pie from '@/pages/inside/sections/dashboards/charts/Pie';
    import Gauge from '@/pages/inside/sections/dashboards/charts/Gauge';
    export default {
        name: 'AreaPie',
        components: {
            // Pie,
            Gauge
        },
        props: {
            area: {
                type: Object,
                required: true
            }
        },
        computed: {
            areaSecondData(){
                if(this.area.has_opportunities_plus){
                    return this.area.opportunities_plus_target_percent_reached;
                }

                return 0;
            }
        },
        methods: {
            goToArea(area) {
                this.$emit('goToArea',area)
            }
        }
    }
</script>